<template>
  <div class="edit-selected-operator-rates-sidebar">
    <div class="edit-selected-operator-rates-sidebar--content">
      <SelectedOperatorDetail mode="edit" />
    </div>
    <div class="cr-sidebar-dialog--button-spacer"></div>
    <div>
      <CRButton
        id="edit-selected-operator-rates-save-btn"
        class="edit-selected-operator-rates-sidebar--action-btn"
        color="primary"
        :disabled="!rates.length"
        @click="submit"
      >
        Save Updates
      </CRButton>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { deepClone } from '@/utils/deepClone'
import marketRates from '@/services/marketRates'
import SelectedOperatorDetail from './SelectedOperatorDetail.vue'

export default {
  components: {
    SelectedOperatorDetail,
  },
  computed: {
    companyId() {
      return this.$store.getters['selectedOperator/getCompanyId']
    },
    customerAccountId() {
      return this.$store.getters['customerAccounts/getCustomerAccountId']
    },
    operator() {
      return this.$store.getters['selectedOperator/getOperator']
    },
    rates() {
      return this.$store.getters['selectedOperator/getRates']
    },
  },
  methods: {
    ...mapActions({
      showAlert: 'app/showAlert',
      clearSelectedOperator: 'selectedOperator/clearSelectedOperator',
    }),
    async submit() {
      if (!this.rates.length) {
        this.showAlert({
          type: 'error',
          message: 'No rates to update',
        })
        if (this.isModeEdit) {
          this.$store.dispatch('app/closeSidebarDialog')
        }
      }
      const updateRates = deepClone(this.rates)
      for (const vtr of updateRates) {
        for (const rate of vtr.rates) {
          if (!rate.toggled) {
            rate.custom = null
          }
        }
      }
      const params = {
        customerAccountId: this.customerAccountId,
        companyId: this.companyId,
        body: {
          vehicleTypeRates: updateRates,
        },
      }

      try {
        const res = await marketRates.updateSelectedOperatorRates(params)
      } catch (e) {
        this.showAlert({
          type: 'error',
          message: 'Unable to update selected operator rates',
        })
        console.error(e)
      }
      this.$nextTick(() => {
        this.showAlert({
          type: 'success',
          message: 'Selected operator rates updated',
        })
      })
      this.close()
    },
    close() {
      this.clearSelectedOperator()
      this.$store.dispatch('app/closeSidebarDialog')
    },
  },
}
</script>

<style lang="scss" scoped>
.edit-selected-operator-rates-sidebar {
  height: 100%;
  width: 500px;

  &--content {
    flex: 1;
    margin: 20px;
  }

  &--action-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    font-size: 18px;
    bottom: 0;
    width: 500px !important;
    height: 71px !important;
    padding: 24px 0;
    width: inherit;
    border-radius: 0;
  }
}

::v-deep .cr-sidebar-dialog::-webkit-scrollbar {
  display: none;
}
</style>
